// Odd error with Webpack if not full path - more reasons to remove barrels
import { Role } from '@app/shared/data/enums/role';

import { AuthService } from '../services/auth.service';

export enum Permission {
	EDIT_SETTINGS = 'EDIT_SETTINGS',
	EDIT_PLANNING = 'EDIT_PLANNING',
	EDIT_MODEL = 'EDIT_MODEL',
	EDIT_MARKETING = 'EDIT_MARKETING',
	ANALYTICS = 'ANALYTICS',
	ANALYTICS_VALIDATION = 'ANALYTICS_VALIDATION',
	SALES_ANALYTICS = 'SALES_ANALYTICS',
	MARKETING_ANALYTICS = 'MARKETING_ANALYTICS',
	CHANGE_TENANT = 'CHANGE_TENANT',
	CREATE_TENANT = 'CREATE_TENANT',
}

const permissionMap: Map<Permission, { parent?: Permission; roles: string[] }> = new Map([
	[Permission.EDIT_SETTINGS, { roles: [Role.Admin, Role.AdminValidator] }],
	[Permission.EDIT_PLANNING, { roles: [Role.Admin, Role.AdminValidator, Role.Executive] }],
	[Permission.EDIT_MODEL, { roles: [Role.Admin, Role.AdminValidator] }],
	[Permission.EDIT_MARKETING, { roles: [Role.Admin, Role.AdminValidator, Role.Marketing] }],
	[Permission.ANALYTICS, { roles: [Role.Admin, Role.AdminValidator, Role.Executive] }],
	[Permission.ANALYTICS_VALIDATION, { roles: [Role.AdminValidator] }],
	[Permission.SALES_ANALYTICS, { parent: Permission.ANALYTICS, roles: [Role.Marketing, Role.Sales] }],
	[Permission.MARKETING_ANALYTICS, { parent: Permission.ANALYTICS, roles: [Role.Marketing] }],
]);

export class PermissionManager {
	static hasPermission(permission: Permission): boolean {
		const role = AuthService.getRole();
		if (role === Role.SuperAdmin) return true;

		const access = permissionMap.get(permission) ?? { roles: [] };
		return access.roles.includes(role) || (access.parent != null && this.hasPermission(access.parent));
	}

	static hasPermissionOrChildPermission(permission: Permission): boolean {
		const role = AuthService.getRole();
		if (role === Role.SuperAdmin) return true;

		const access = permissionMap.get(permission) ?? { roles: [] };
		const children = Array.from(permissionMap.entries()).filter((p) => p[1].parent === permission);
		return access.roles.includes(role) || children.some((c) => this.hasPermissionOrChildPermission(c[0]));
	}
}
