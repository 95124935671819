import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { getMonth, getQuarter } from 'date-fns';
import { firstValueFrom, map, Observable, shareReplay, switchMap } from 'rxjs';

import { WebStorage } from '@app/shared/core';
import {
	DAOCollectionFactory, DaoFactory, isRevenueStreamActive, Path, RevenueStreamDAO, sortRevenueStreams, StrategyDAO,
	strategyStartDate, Tenant, TenantDAO, UserDAO,
} from '@app/shared/data';

import { LocalStorageKeys } from '../constants';

@Injectable({
	providedIn: 'root',
})
export class TenantService {
	private _tenantDAO: Observable<TenantDAO>;

	static get tenantId(): string {
		return WebStorage.getItem(LocalStorageKeys.TenantId);
	}

	constructor(
		private daoFactory: DaoFactory,
		private daoCollectionFactory: DAOCollectionFactory,
		private router: Router
	) {}

	getTenant(): Observable<TenantDAO> {
		if (!this._tenantDAO) {
			this._tenantDAO = this.daoFactory.build<Tenant, TenantDAO>(TenantDAO, Path.tenant(TenantService.tenantId));
		}
		return this._tenantDAO;
	}

	getAllStrategies(): Observable<StrategyDAO[]> {
		return this.getTenant().pipe(switchMap((tenant) => tenant.strategies));
	}

	getAllRevenueStreams(): Observable<RevenueStreamDAO[]> {
		return this.getTenant().pipe(switchMap((tenant) => tenant.revenueStreams));
	}

	async determineStartingStrategyYear(): Promise<number> {
		const cached = WebStorage.getItem<number>(LocalStorageKeys.SelectedStrategyYear);
		if (cached) return cached;

		const today = new Date();
		const todaysYear = today.getFullYear();
		const strats = await firstValueFrom(this.getAllStrategies());
		const startingStrategy = strats.find((x) => x.snapshot.year === todaysYear)?.snapshot;
		if (!startingStrategy) return todaysYear; // This should never happen

		// negating the inequality to account for undefined because reversing the inequality would not work
		if (!(startingStrategy.startingQuarter > 0) && !(startingStrategy.startingMonth > 0)) return todaysYear;
		if (startingStrategy.startingQuarter > 0) {
			const todaysQuarter = getQuarter(today);
			if (todaysQuarter >= startingStrategy.startingQuarter) return todaysYear;
			else return todaysYear - 1;
		} else {
			// must have startingMonth
			const todaysMonth = getMonth(today);
			if (todaysMonth >= startingStrategy.startingMonth) return todaysYear;
			else return todaysYear - 1;
		}
	}

	static getYearStart(strategies: StrategyDAO[], year: number) {
		const matching = strategies.find((s) => s.snapshot.year === year);
		if (matching) return strategyStartDate(matching.snapshot);

		const sorted = strategies.sort((a, b) => a.snapshot.year - b.snapshot.year);
		if (year < sorted[0].snapshot.year) {
			return strategyStartDate({ ...sorted[0].snapshot, year });
		} else {
			return strategyStartDate({ ...sorted.at(-1).snapshot, year });
		}
	}

	getRevenueStreamsByYear(year: number) {
		const strategyStart$ = this.getAllStrategies().pipe(map((ss) => TenantService.getYearStart(ss, year)));
		return strategyStart$.pipe(switchMap((date) => this.getRevenueStreams(date)));
	}

	getRevenueStreams(strategyStart: Date): Observable<RevenueStreamDAO[]> {
		return this.getTenant().pipe(
			switchMap((t) => t.revenueStreams),
			map((streams) =>
				streams
					.filter((s) => isRevenueStreamActive(s.snapshot, strategyStart))
					.sort((a, b) => sortRevenueStreams(a.snapshot, b.snapshot))
			)
		);
	}

	getUsers(includeInactive = false) {
		return this.daoCollectionFactory
			.build(UserDAO, Path.users(), (user) =>
				user.where('tenantId', '==', WebStorage.getItem(LocalStorageKeys.TenantId))
			)
			.pipe(
				map((us) =>
					us
						.filter((u) => !u.snapshot.isDeleted || includeInactive)
						.sort((a, b) => a.fullName.localeCompare(b.fullName))
				),
				shareReplay(1)
			);
	}

	async switchToTenant(tenantId: string, route?: string) {
		WebStorage.setItem(LocalStorageKeys.TenantId, tenantId);
		WebStorage.removeItem(LocalStorageKeys.SelectedStrategyYear);
		const startingYear = await this.determineStartingStrategyYear();
		WebStorage.setItem(LocalStorageKeys.SelectedStrategyYear, startingYear);
		if (route) {
			await this.router.navigateByUrl(route);
		}
		window.location.reload();
	}
}
